import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton, Popover } from "antd";
import { Button } from "client/src/components/Button/Button";
import { useState, useCallback } from "react";
import { RouteData } from "shared/config/routeData";

import { InternalLinkButton } from "../../components/Button/InternalLinkButton";
import { HorizontalDivider } from "../../components/HorizontalDivider/HorizontalDivider";
import { StackY, StackX } from "../../components/Spacing/Spacing";
import { Body3, Body5 } from "../../components/Typography/Typography";
import { useCloseOnLeaveFocus } from "../../hooks/useCloseOnLeaveFocus";
import { useCloseOnPressEscape } from "../../hooks/useCloseOnPressEscape";

import * as styles from "./navItemPopover.module.less";

import type { ClientId } from "../../../../shared/types/Client";
import type { logOutType } from "./NavBarPage";

type NavItemPopoverProps = {
  track?: (buttonLabel: string) => void;
  clientId?: ClientId;
  clientName?: string | null;
  userName: string;
  email: string | undefined;
  logOut: logOutType;
};

export function NavItemPopover(props: NavItemPopoverProps) {
  const { clientId, clientName, userName, email, logOut, track } = props;

  const [popoverVisible, setPopoverVisible] = useState(false);

  const visibleChangeClick = useCallback(
    (visible: boolean) => {
      track?.("User's name");
      setPopoverVisible(visible);
    },
    [track],
  );

  const companyProfileClick = useCallback(() => {
    track?.("Company profile");
    setPopoverVisible(false);
  }, [track]);
  const logOutClick = useCallback(async () => {
    track?.("Sign Out");
    await logOut({ data: {} });
  }, [track, logOut]);

  useCloseOnPressEscape({ popoverVisible, setPopoverVisible });

  const containerRef = useCloseOnLeaveFocus({ popoverVisible, setPopoverVisible });

  if (!userName) {
    return (
      <Skeleton
        active
        paragraph={{ width: 90, rows: 1 }}
        title={false}
        className={styles.navLinkSkeleton}
      />
    );
  }

  return (
    <span ref={containerRef}>
      <Popover
        getPopupContainer={() => {
          if (containerRef.current) {
            return containerRef.current;
          }
          throw new Error("Container ref not initialized");
        }}
        trigger={["click"]}
        placement="bottomRight"
        open={popoverVisible}
        onOpenChange={visibleChangeClick}
        overlayClassName={styles.popover}
        content={
          <div>
            <StackY dist={8}>
              {clientName && <Body3 as="div">{clientName}</Body3>}
              <Body3 as="div">{userName}</Body3>
              {email && <Body5 as="div">{email}</Body5>}
            </StackY>

            <HorizontalDivider />

            <StackY dist={12}>
              {clientId && (
                <div>
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={RouteData.clientTaskDetail.getPath(clientId, "company-profile")}
                    onClick={companyProfileClick}
                  >
                    Company profile
                  </InternalLinkButton>
                </div>
              )}

              <div>
                <Button type="text-only-link" size="middle" onClick={logOutClick}>
                  Sign Out
                </Button>
              </div>
            </StackY>
          </div>
        }
      >
        <Button
          size="middle"
          type="text-only-link"
          onClick={() => setPopoverVisible((prev) => !prev)}
        >
          <StackX dist={8}>
            <span>{userName}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </StackX>
        </Button>
      </Popover>
    </span>
  );
}
