import { Skeleton } from "antd";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { getShouldDisplayDueDate } from "../../utils/getShouldDisplayDueDate";
import { Back } from "../Back/Back";
import { Circle } from "../Circle/Circle";
import { DueDate } from "../DueDate/DueDate";
import BackArrow from "../Icons/BackArrow.svg?react";
import { H3, Body1 } from "../Typography/Typography";

import * as styles from "./childPageLayout.module.less";

import type { Task } from "../../../../shared/data/Tasks";

export type ChildPageLayoutProps = {
  topNavTitle: string;
  task?: Task;
  aside: JSX.Element;
  contents: JSX.Element;
  bottomToolbar?: JSX.Element;
  onClickBack?: () => void;
};

export function ChildPageLayout({
  topNavTitle,
  task,
  aside,
  contents,
  bottomToolbar,
  onClickBack,
}: ChildPageLayoutProps) {
  const { clientId } = useClientHubParams();

  const { ref: asideRef, entry } = useIntersectionObserver<HTMLDivElement>();

  const shouldDisplayDueDate = task ? getShouldDisplayDueDate(task) : false;

  return (
    <div>
      <div className={styles.layout}>
        <div
          className={clsx(styles.topNav, entry?.isIntersecting === false && styles.topNav__visible)}
          {...{ inert: entry?.isIntersecting === true ? "" : undefined }}
        >
          <div className={styles.topNavInner}>
            <div>
              <Back onClick={onClickBack} to={RouteData.homeHub.getPath(clientId ?? "")}>
                Back to Home
              </Back>
            </div>

            <div className="ml-48">
              <H3 as="span">{topNavTitle}</H3>
            </div>

            {shouldDisplayDueDate && task && (
              <>
                <div className="mx-24">|</div>

                <DueDate task={task} />
              </>
            )}
          </div>
        </div>

        <div className={styles.aside}>
          <div className={styles.circlePosition}>
            <Circle variant="blue" />
          </div>
          <div className={styles.circleThinPosition}>
            <Circle thin variant="blue" />
          </div>

          <div className={styles.asideInner}>
            <div className={styles.backLink} ref={asideRef}>
              <Body1>
                <Link
                  to={RouteData.homeHub.getPath(clientId ?? "")}
                  className={`stack-x-8 py-8 ${styles.alignCenterInline}`}
                >
                  <BackArrow />
                  <span>Back to Home</span>
                </Link>
              </Body1>
            </div>

            {aside}
          </div>
        </div>

        <div className={styles.contents}>
          <div className={styles.contentsInner}>{contents}</div>
        </div>
      </div>
      {bottomToolbar}
    </div>
  );
}

export function ChildPageSkeleton() {
  return (
    <ChildPageLayout
      topNavTitle="-"
      aside={
        <>
          <Skeleton active />
          <Skeleton active title={false} />
          <br />
          <Skeleton active title={false} />
        </>
      }
      contents={
        <>
          <Skeleton
            active
            paragraph={{
              rows: 6,
            }}
          />
          <br />
          <Skeleton
            active
            paragraph={{
              rows: 8,
            }}
          />
          <br />
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 8,
            }}
          />
        </>
      }
    />
  );
}
