import { isChangeDetailInfo } from "shared/types/Change";
import { relevantChangesForContactFields } from "shared/types/Contact";
import { eifStepNavigation } from "shared/types/EIF";
import {
  getPlansWhereCanSetClaimsCheckLocations,
  relevantChangesForNonClassPlans,
  type Plan,
} from "shared/types/Plan";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { rejectNullableValues } from "shared/utils/utils";
import type { Bill } from "shared/types/Bill";
import type {
  DEIFChangeSnapshot,
  ChangeDetailInfo,
  ChangeDetailInfoList,
} from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type {
  EIFCompanyDetailsSubStep,
  EIFOtherContractDetailsSubStep,
  EIFPlanAdministratorsAndBillingSubStepMap,
  EIFPlanConfigEligibilityOnlyPFMLSubStep,
  EIFStepId,
  EIFSubStepId,
} from "shared/types/EIF";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type StepArgs = {
  eifStepId: EIFStepId;
  changeSnapshot: DEIFChangeSnapshot;
  client: Client;
  contacts: Contact[];
  bills: Bill[];
  deletedBills: Bill[];
  clientPlans: Plan[];
  featureToggles: ClientFeatureToggles;
  subsidiaries: Subsidiary[];
};

export function getChangeDetailInfoListForStep(args: StepArgs) {
  const {
    eifStepId,
    changeSnapshot,
    client,
    contacts,
    bills,
    deletedBills,
    clientPlans,
    subsidiaries,
  } = args;

  const subSteps = eifStepNavigation[eifStepId];

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const subStepIds = Object.entries(subSteps) as [EIFSubStepId, string][];

  const changeListForCurrentStep = subStepIds.map(
    ([eifSubStepId, subStepName]): [EIFSubStepId, string, ChangeDetailInfo[]] => {
      const list = getChangeDetailInfoListForSubStep({
        eifSubStepId,
        changeSnapshot,
        client,
        contacts,
        clientPlans,
        bills,
        deletedBills,
        policies: client.policies,
        subsidiaries,
      }).filter(rejectNullableValues);
      const item: [EIFSubStepId, string, ChangeDetailInfo[]] = [eifSubStepId, subStepName, list];
      return item;
    },
  );

  return changeListForCurrentStep;
}

type SubStepArgs = {
  changeSnapshot: DEIFChangeSnapshot;
} & (
  | {
      eifSubStepId: "benefits-administration-and-data-feed";
      policies: Policy[];
    }
  | {
      eifSubStepId: "termination-of-insurance";
      clientPlans: Plan[];
    }
  | {
      eifSubStepId: "rehire-provision";
    }
  | {
      eifSubStepId: "erisa";
      client: Client;
    }
  | {
      eifSubStepId: "claims-check-mailing-locations";
      clientPlans: Plan[];
    }
  | {
      eifSubStepId: "monthly-claims-reports-and-eobs";
      client: Client;
    }
  | {
      eifSubStepId: "billing-preferences";
      bills: Bill[];
      deletedBills: Bill[];
      policies: Policy[];
    }
  | {
      eifSubStepId: "plan-administrators";
      contacts: Contact[];
    }
  | {
      eifSubStepId: "subsidiaries-and-affiliates";
      subsidiaries: Subsidiary[];
    }
  | {
      eifSubStepId: "non-class-benefits-preferences";
      clientPlans: Plan[];
    }
  | {
      eifSubStepId: Exclude<
        EIFSubStepId,
        | "benefits-administration-and-data-feed"
        | "termination-of-insurance"
        | "rehire-provision"
        | "erisa"
        | "claims-check-mailing-locations"
        | "monthly-claims-reports-and-eobs"
        | "billing-preferences"
        | "plan-administrators"
        | "subsidiaries-and-affiliates"
        | "non-class-benefits-preferences"
      >;
    }
);

export function getChangeDetailInfoListForSubStep(args: SubStepArgs) {
  const { eifSubStepId, changeSnapshot } = args;

  switch (eifSubStepId) {
    // Company Information
    case "benefits-administration-and-data-feed": {
      const { policies } = args;

      const list = policies
        .map((policy) => {
          const policyChanges = [
            // Setup changes
            changeSnapshot.Policy[policy.id]?.hasBenAdminPlatform,
            changeSnapshot.Policy[policy.id]?.benAdminPlatformId,
            changeSnapshot.Policy[policy.id]?.dataFeeds,
            changeSnapshot.Policy[policy.id]?.benAdminPlatformOtherName,

            // Data Feed Ben Admin Contact changes
            changeSnapshot.Contact[policy.dataFeedsBenAdminContact?.id ?? ""]?.firstName,
            changeSnapshot.Contact[policy.dataFeedsBenAdminContact?.id ?? ""]?.lastName,
            changeSnapshot.Contact[policy.dataFeedsBenAdminContact?.id ?? ""]?.email,

            // Data Feed Production Support Contact changes
            changeSnapshot.Contact[policy.dataFeedsProductionSupportContact?.id ?? ""]?.firstName,
            changeSnapshot.Contact[policy.dataFeedsProductionSupportContact?.id ?? ""]?.lastName,
            changeSnapshot.Contact[policy.dataFeedsProductionSupportContact?.id ?? ""]?.email,

            // Data Feed Implementor Contact changes
            changeSnapshot.Contact[policy.dataFeedsImplementorContact?.id ?? ""]?.firstName,
            changeSnapshot.Contact[policy.dataFeedsImplementorContact?.id ?? ""]?.lastName,
            changeSnapshot.Contact[policy.dataFeedsImplementorContact?.id ?? ""]?.email,
          ].filter(rejectNullableValues);

          return policyChanges;
        })
        .flat();

      return list;
    }
    case "tax-id":
      return [changeSnapshot.Client.taxId];
    case "subsidiaries-and-affiliates": {
      const subsidiaryChanges = Object.values(changeSnapshot.Subsidiary)
        .filter(rejectNullableValues)
        .flatMap((recordWithMetadata) => {
          const { metadata, ...record } = recordWithMetadata;
          return Object.values(record);
        });

      const locationIds = args.subsidiaries.map((subsidiary) => subsidiary.location?.id);

      const subsidiaryLocationChanges = Object.entries(changeSnapshot.Location)
        .map(([locationId, location]) => {
          if (!locationIds.includes(locationId)) {
            return null;
          }
          return location;
        })
        .filter(rejectNullableValues)
        .flatMap((recordWithMetadata) => {
          const { metadata, ...record } = recordWithMetadata;
          return Object.values(record);
        });

      return [
        changeSnapshot.Client.hasSubsidiary,
        changeSnapshot.Client.subsidiaryEEsInNYNJOrHI,
        changeSnapshot.Client.mainPolicyHolderEEsInNYNJOrHI,
        changeSnapshot.Client.mainPolicyHolderEEStates,
        ...subsidiaryChanges,
        ...subsidiaryLocationChanges,
      ];
    }
    case "current-sun-life-policies":
      return [
        changeSnapshot.Client.previouslyInsuredBySunLife,
        changeSnapshot.Client.previousSlfPolicies,
      ];
    case "previous-coverage-information":
      return [changeSnapshot.Client.benefitsReplacedWithSlf];

    // Plan Administratin & Billing
    case "plan-administrators": {
      const changedContactIdsForAllContacts = Object.keys(changeSnapshot.Contact);
      const adminIds = args.contacts
        .filter((contact) => contact.type === "PRIMARY_WEB_ADMIN" || contact.type === "WEB_ADMIN")
        .map((contact) => contact.id);
      const contactIds = changedContactIdsForAllContacts.filter((cid) => adminIds.includes(cid));
      const changes = relevantChangesForContactFields(contactIds, changeSnapshot);
      return changes;
    }
    case "billing-preferences": {
      const { bills, deletedBills, policies, changeSnapshot } = args;

      const policyIds = new Set(policies.map((p) => p.id));

      const allBills = bills.concat(deletedBills);

      const billsInThisPolicy = allBills.filter((b) => b.policyId && policyIds.has(b.policyId));
      const billsInThisPolicyMap = new Map(billsInThisPolicy.map((b) => [b.id, b]));

      const billsChangeDetailInfoList = Object.entries(changeSnapshot.Bill)
        .concat(Object.entries(changeSnapshot.BillLocation))
        .filter(([billId, _]) => {
          const bill = billsInThisPolicyMap.get(billId);
          const keep = bill?.policyId && policyIds.has(bill.policyId);
          return keep;
        })
        .flatMap(([_, values]) => Object.values(values || {}))
        .filter(isChangeDetailInfo);

      const billingPreferencesChangeDetailInfoList = policies.flatMap((policy) =>
        [
          changeSnapshot.Policy[policy.id]?.billingAdministrationType,
          changeSnapshot.Policy[policy.id]?.billingStructureType,
          changeSnapshot.Policy[policy.id]?.billingSummaryStatementType,
          changeSnapshot.Policy[policy.id]?.billPayrollCycles,
          changeSnapshot.Policy[policy.id]?.billPayrollCyclesOther,
          changeSnapshot.Policy[policy.id]?.billPayrollCyclesExplanation,
          changeSnapshot.Policy[policy.id]?.billSplitType,
          changeSnapshot.Policy[policy.id]?.advanceOrArrears,

          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.tpaFirmName,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.firstName,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.lastName,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.email,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.phoneNumber,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.title,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.address1,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.address2,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.city,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.state,
          changeSnapshot.Contact[policy.tpaContact?.id ?? ""]?.zipCode,

          ...billsChangeDetailInfoList,
        ].filter(rejectNullableValues),
      );

      return billingPreferencesChangeDetailInfoList;
    }

    case "claims-check-mailing-locations": {
      const { clientPlans } = args;
      const { changesForRelevantPlans } = getClaimsCheckRelevantPlansAndChanges(
        clientPlans,
        changeSnapshot,
      );
      return changesForRelevantPlans;
    }

    case "monthly-claims-reports-and-eobs": {
      const list = Object.values(changeSnapshot.MonthlyClaimsReportMailingLocation)
        .filter(rejectNullableValues)
        .flatMap((recordWithMetadata) => {
          const { metadata, ...record } = recordWithMetadata;
          return Object.values(record);
        });
      return list;
    }

    // Plan configuration & Eligibility
    case "class-builder": {
      // Any change to EmployeeClass or EmployeeClassPlan
      const list = Object.values(changeSnapshot.EmployeeClass)
        .concat(Object.values(changeSnapshot.EmployeeClassPlan))
        .filter(rejectNullableValues)
        .flatMap((recordWithMetadata) => {
          const { metadata, ...record } = recordWithMetadata;
          return Object.values(record);
        });
      return list;
    }
    case "non-class-benefits-preferences": {
      return relevantChangesForNonClassPlans(args.clientPlans, changeSnapshot);
    }
    case "fli-preferences": {
      return [changeSnapshot.Client.fliCoversAllEmployees, changeSnapshot.Client.fliExcludedGroups];
    }

    // Other Contract Details
    case "confirm-policy-anniversary":
      return [changeSnapshot.Client.policyAnniversaryMonth];
    case "additional-waiting-period-rules":
      return [
        changeSnapshot.Client.timeSpentShouldCountTowardsWaitingPeriod,
        changeSnapshot.Client.timeSpentAsPartTimeShouldCount,
        changeSnapshot.Client.timeSpentAsOtherShouldCount,
        changeSnapshot.Client.timeSpentAsOtherShouldCountText,
        changeSnapshot.Client.waitingPeriodShouldBeWaived,
      ];
    case "rehire-provision":
      return [changeSnapshot.Client.reHireProvisionExists, changeSnapshot.Client.rehireProvision];
    case "union-members-and-domestic-partners":
      return [
        changeSnapshot.Client.unionMembersCovered,
        changeSnapshot.Client.domesticPartnersCovered,
      ];
    case "changes-during-annual-enrollment":
      return [
        changeSnapshot.Client.whenDoYouAdministerYourAEP,
        changeSnapshot.Client.whenDoAEPTakeEffect,
        changeSnapshot.Client.whenDoAEPTakeEffectOther,
        changeSnapshot.Client.whenDecreasesInsuranceTakeEffect,
        changeSnapshot.Client.changesOnlyAllowedDuringAE,
        changeSnapshot.Client.whenDoChangesToBenefitsTakeEffect,
      ];
    case "termination-of-insurance": {
      const { clientPlans } = args;
      const changeListForTermination = clientPlans
        .map((clientPlan) => changeSnapshot.Plan[clientPlan.id]?.terminationDate)
        .filter((i): i is ChangeDetailInfo => i != null);
      return changeListForTermination;
    }
    case "age-rules":
      return [
        changeSnapshot.Client.ageReductionDecreaseTakeEffectWhen,
        changeSnapshot.Client.ageBandIncreaseTakeEffectWhen,
        changeSnapshot.Client.ageForDependentLifeBenefits,
        changeSnapshot.Client.criticalIllnessDependentAge,
        changeSnapshot.Client.criticalIllnessDependentTobacco,
      ];
    case "other-changes-to-coverage":
      return [changeSnapshot.Client.otherCoverageChangesTakeEffectWhen];
    case "employee-certificate":
      return [
        changeSnapshot.Client.shouldCertificatesBeSplitInAnotherWay,
        changeSnapshot.Client.shouldCertificatesBeSplitInAnotherWayText,
      ];
    case "section-125":
      return [
        changeSnapshot.Client.hasSection125Plan,
        changeSnapshot.Client.hasSection125Dental,
        changeSnapshot.Client.hasSection125Vision,
        changeSnapshot.Client.hasAdditionalCoverageIncludedIn125Plan,
        changeSnapshot.Client.additonalCoverageIncludedInSection125Plan,
      ];
    case "erisa": {
      const { client } = args;
      const erisaChangeDetailInfoList = [
        changeSnapshot.Client.erisaHasPlan,
        changeSnapshot.Client.erisaPrefersPlanDetails,
        changeSnapshot.Client.erisaPlanType,
        changeSnapshot.Client.erisaPlanTypeOther,
        changeSnapshot.Client.erisaPlanAdminName,
        changeSnapshot.Client.erisaStreetAddress,
        changeSnapshot.Client.erisaCity,
        changeSnapshot.Client.erisaState,
        changeSnapshot.Client.erisaZipCode,
        changeSnapshot.Client.erisaPlanNumber,
        changeSnapshot.Client.erisaPlanNumberOther,
        changeSnapshot.Client.erisaPlanYearEndDate_deprecated,
        changeSnapshot.Client.erisaPlanYearEndDateMMDD,
        changeSnapshot.Client.erisaAdministerOwnPlan,
        changeSnapshot.Client.erisaHasAgentForLegalProcess,
        changeSnapshot.Client.erisaIsLegalAgentSameAsAdmin,
        changeSnapshot.Client.erisaLegalAgentName,
        changeSnapshot.Client.erisaLegalAgentStreetAddress,
        changeSnapshot.Client.erisaLegalAgentCity,
        changeSnapshot.Client.erisaLegalAgentState,
        changeSnapshot.Client.erisaLegalAgentZipCode,
      ];
      // relevant if currently YES or was YES and not changing
      const isTaxIdChangeRelevant =
        (changeSnapshot.Client.erisaPrefersPlanDetails &&
          changeSnapshot.Client.erisaPrefersPlanDetails.currentValue.value === "YES") ||
        (client.erisaPrefersPlanDetails === "YES" &&
          !(
            changeSnapshot.Client.erisaPrefersPlanDetails &&
            changeSnapshot.Client.erisaPrefersPlanDetails.currentValue.value === "NO"
          ));

      /* client.tax id can be set in Company Details and in Other Contract Details section
       *  only include tax id change here if relevant to ERISA (i.e. erisaPrefersPlanDetails)
       */
      if (isTaxIdChangeRelevant) {
        return [...erisaChangeDetailInfoList, changeSnapshot.Client.taxId].filter(
          rejectNullableValues,
        );
      }

      return erisaChangeDetailInfoList.filter(rejectNullableValues);
    }
    case "value-added-services":
      return [changeSnapshot.Client.valueAddedServicesOptions];
    case "malpractice-reimbursements": {
      return [
        changeSnapshot.Client.malpracticeReimbursementRider,
        changeSnapshot.Client.malpracticeReimbursementPayableTo,
      ];
    }
    default:
      exhaustiveCheck(eifSubStepId);
  }

  return [];
}

export type EIFSubStepsHaveChanges<T extends EIFSubStepId> = {
  [K in T]: boolean;
};

export type EIFSubStepsHaveChangesByStep = {
  "company-information": EIFSubStepsHaveChanges<EIFCompanyDetailsSubStep>;
  "plan-administrators-&-billing": EIFSubStepsHaveChanges<EIFPlanAdministratorsAndBillingSubStepMap>;
  "other-contract-details": EIFSubStepsHaveChanges<EIFOtherContractDetailsSubStep>;
  "plan-configuration-&-eligibility": EIFSubStepsHaveChanges<EIFPlanConfigEligibilityOnlyPFMLSubStep>;
};

export function getEIFSubStepsHaveChanges(
  args: Omit<StepArgs, "eifStepId">,
): EIFSubStepsHaveChangesByStep {
  const subStepsHaveChangesBySteps: EIFSubStepsHaveChangesByStep = {
    "company-information": {
      "benefits-administration-and-data-feed": false,
      "current-sun-life-policies": false,
      "previous-coverage-information": false,
      "tax-id": false,
      "subsidiaries-and-affiliates": false,
    },
    "plan-administrators-&-billing": {
      "billing-preferences": false,
      "claims-check-mailing-locations": false,
      "malpractice-reimbursements": false,
      "monthly-claims-reports-and-eobs": false,
      "plan-administrators": false,
    },
    "other-contract-details": {
      "confirm-policy-anniversary": false,
      "additional-waiting-period-rules": false,
      "rehire-provision": false,
      "union-members-and-domestic-partners": false,
      "changes-during-annual-enrollment": false,
      "termination-of-insurance": false,
      "age-rules": false,
      "other-changes-to-coverage": false,
      "employee-certificate": false,
      "section-125": false,
      erisa: false,
      "value-added-services": false,
    },
    "plan-configuration-&-eligibility": {
      "class-builder": false,
      "fli-preferences": false,
      "non-class-benefits-preferences": false,
    },
  };

  const stepsToGetSubStepChangesFor: Exclude<EIFStepId, "review-&-submit">[] = [
    "company-information",
    "plan-administrators-&-billing",
    "other-contract-details",
    "plan-configuration-&-eligibility",
  ];

  stepsToGetSubStepChangesFor.forEach((stepId) =>
    setSubStepsHaveChangesForStep(stepId, args, subStepsHaveChangesBySteps),
  );

  return subStepsHaveChangesBySteps;
}

function setSubStepsHaveChangesForStep(
  stepId: Exclude<EIFStepId, "review-&-submit">,
  args: Omit<StepArgs, "eifStepId">,
  subStepsHaveChangesBySteps: EIFSubStepsHaveChangesByStep,
) {
  const {
    changeSnapshot,
    client,
    contacts,
    bills,
    deletedBills,
    clientPlans,
    featureToggles,
    subsidiaries,
  } = args;
  const cdli = getChangeDetailInfoListForStep({
    eifStepId: stepId,
    client,
    bills,
    deletedBills,
    changeSnapshot,
    clientPlans,
    contacts,
    featureToggles,
    subsidiaries,
  });

  cdli.forEach(([eifSubStepId, _subStepName, changeDetailInfoList]) => {
    switch (stepId) {
      case "company-information":
        subStepsHaveChangesBySteps[stepId][
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
          eifSubStepId as EIFCompanyDetailsSubStep
        ] = changeDetailInfoList.length > 0;
        break;
      case "plan-administrators-&-billing":
        subStepsHaveChangesBySteps[stepId][
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
          eifSubStepId as EIFPlanAdministratorsAndBillingSubStepMap
        ] = changeDetailInfoList.length > 0;
        break;
      case "plan-configuration-&-eligibility":
        subStepsHaveChangesBySteps[stepId][
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
          eifSubStepId as EIFPlanConfigEligibilityOnlyPFMLSubStep
        ] = changeDetailInfoList.length > 0;
        break;
      case "other-contract-details":
        subStepsHaveChangesBySteps[stepId][
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
          eifSubStepId as EIFOtherContractDetailsSubStep
        ] = changeDetailInfoList.length > 0;
        break;
      default:
        exhaustiveCheck(stepId);
        break;
    }
  });
}

export function anyChangesForSubSteps<T extends EIFSubStepId>(
  subStepsHaveChanges: EIFSubStepsHaveChanges<T>,
) {
  return Object.values(subStepsHaveChanges).some((val) => val === true);
}

export function getChangeListDetailInfoIsEmpty(changeDetailInfoList: ChangeDetailInfoList) {
  if (changeDetailInfoList.length === 0) {
    return true;
  }
  const isEmpty = changeDetailInfoList.every((cdlInfo) => cdlInfo == null);
  return isEmpty;
}

export const getClaimsCheckRelevantPlansAndChanges = (
  plans: Plan[],
  changeSnapshot: DEIFChangeSnapshot,
) => {
  const relevantPlans = getPlansWhereCanSetClaimsCheckLocations(plans);

  const relevantPlanIds = relevantPlans.map((plan) => plan.id);

  const changesForRelevantPlans = relevantPlanIds
    .flatMap((id) => [
      changeSnapshot.Plan[id]?.sendClaimsCheckTo,
      changeSnapshot.Plan[id]?.claimsCheckPayee,
      changeSnapshot.Plan[id]?.planAdminPayeeContactId,
      changeSnapshot.Plan[id]?.someoneElsePayeeContactId,
    ])
    .filter(rejectNullableValues);

  return { relevantPlans, changesForRelevantPlans };
};
